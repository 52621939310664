@import "toastr";
.MuiDataGrid-row .MuiDataGrid-cell {
    overflow: inherit !important;
}

.main-loader {
    text-align: center;
    margin-top: 100px;
}

.main-loader .spinner-grow {
    margin: 8px;
}

/* Sidebar Scroll Begins */
#sidebar {
    display: block;
    width: 150px;
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    left: 0;
    top: 0;
    z-index: 1;
}

.main {
    margin-left: 254px;
}
/* Sidebar Scroll Ends */

#product-row th, #product-row td  {
    padding: 0.25rem !important;
}

body {
    font-family: "Inter", "Helvetica Neue", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

@media (min-width: 992px) {
    .content {
        padding: 1.5rem !important;
    }
}

form .error {
    color: #ff0000;
}
.required:after {
    content:" *";
    color: red;
}

/** Loader css starts **/
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #3b7ddd;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #3b7ddd transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/** Loader css ends **/

.hidden {
    display: none !important;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination .page-item {
    margin: 0px 5px;
}

.card-header {
    background: #f0f2f58a !important;
}

.select2-container--bootstrap-5 .select2-selection {
    min-height: calc(1.5em + 0.55rem + 2px) !important;
}

.select2-search__field {
    padding: 3px !important;
}

.range-col {
    position: relative;
}

.range-col span {
    position: absolute;
    right: -3px;
    top: 5px;
}

.pagination .active {
    background: #3b7ddd;
    padding: 0px 5px;
}

.pagination .active a {
    color: white;
}

.collapsed .fa-angle-down {
    display: none;
}

.collapsed .fa-angle-right {
    display: inline;
}

#rightSidebar a:hover {
    text-decoration: none !important;
}

.collapse-content {
    border-top: 1px solid lightgray;
    padding-top: 10px;
}

.header-item {
    margin-right: 10px;
    font-size: 14px;
}

body {
    font-size: 14px !important;
}

#rightSidebar .card-body {
    border: 1px solid #ededed;
    padding: 0.75rem;
}

.sidebar {
    font-size: 15px !important;
}

.card-title {
    font-size: 1.1rem;
    font-weight: 500;
    color: #5c6a76;
}

.item-selected {
    background-color: #c9c9c9 !important;
;
}

#info {
    font-size: 18px;
}

.form-check-input {
    height: 20px;
    width: 20px;
}

/* splitter css begins */
.split {
    display: flex;
    flex-direction: row;
}

.gutter {
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: 50%;
    margin: 10px;
}

.gutter.gutter-horizontal {
    /*height: 300px;*/
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}

/* splitter css ends */

.sidebar-dropdown .sidebar-item {
    margin-left: 15px;
    font-size: 14px !important;
}

.sidebar-dropdown .sidebar-link {
    padding: 0.325rem 1.625rem !important;
}

.sidebar-dropdown .sidebar-item.active .sidebar-link {
    color: #3B7DDD;
    border: none !important;
    background: none;
}

.sidebar-dropdown .sidebar-item.active i {
    color: #3B7DDD !important;
}

.help-block {
    color: red;
}

/* bordered drop area */
.filepond--root {
    height: 35vh
}

.select2-container {
    width: 100% !important;
}

.inline-flex {
    display: inline-flex;
    margin-left: 20px;
}

.form-group {
    margin-bottom: 1rem;
}

.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.reset-btn {
    position: absolute;
    right: 5px;
}

.inline-block {
    display: inline-block;
}

.ml10 {
    margin-left: 10px;
}

.btn-filter.active {
    background: #585858;
    color: white;
}

.form-group.form-inline {
    margin-bottom: 0 !important;
}

.payment-master .collapse-content {
    border: none  !important;
}

.bootstrap-collapse:hover {
    text-decoration: none !important;
}

.justify-content-between .btn.float-end {
    max-height: 33px;
}

#file-content-holder {
    max-height: 87vh;
    overflow: scroll;
}

#fileViewerModal .modal-content {
    height: 94vh !important;
}

.invisible_input{
    width: 100%;
    border: none;
}
.jstree-container-ul {
    font-size: 16px;
}

.tab-primary .nav-link.active {
    border-top: 2px solid #3B7DDD;
    border-left: 1px solid #3B7DDD;
    border-right: 1px solid #3B7DDD;
}
/* file upload attachment css */
#drop-area {
    border: 2px dashed #ccc;
    border-radius: 20px;
    margin: 50px auto;
    padding: 20px;
}

#drop-area.highlight {
    border-color: purple;
}

.attachment-form {
    margin-bottom: 10px;
}

#gallery {
    margin-top: 10px;
}

#gallery img {
    width: 150px;
    margin-bottom: 10px;
    margin-right: 10px;
    vertical-align: middle;
}

.attachment-form .button {
    display: inline-block;
    padding: 10px;
    background: #ccc;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.attachment-form .button:hover {
    background: #ddd;
}

#fileElem {
    display: none;
}

.attachment-del-icon{
    padding: 50%;
}

.attachment-del{
    color:white;
    float:right;
}

.attachment-preview-btn{
    margin-bottom:10px;
    background-color: #51A351;
    width: 80%
}

.mt32 {
    margin-top: 32px;
}

.form-check-label {
    margin-top: 5px;
    margin-left: 3px;
}

table.dataTable>tbody>tr.selected>* {
    box-shadow: inset 0 0 0 9999px #aaa !important;
}

.table > :not(:first-child) {
    border-top: 1px solid #dee2e6 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.delete-modal{
    z-index: 1255 !important;
}
.patient-activity-card{
    height: 500px;
    overflow-y: scroll;
}

.table-input-field{
    text-align:center;
}

.nav-link.active {
    background: #3B7DDD;
    color: white;
}

#contentLoader {
    position: absolute;
    top: 30%;
    z-index: 1;
    left: 50%;
}

.checkbox-horizontal {
    margin-right: 30px;
    text-transform: uppercase;
    font-size: 20px;
}

.label-big {
    font-size: 20px;
    margin-right: 10px;
}

#product_order_row {
    margin-left: 10px;
}

.card {
    border: 1px solid gainsboro;
}

.vertical-nav .nav-link.active {
    background: #787878;
}

.ml-30 {
    margin-left: 30px;
}

.popover{
    max-width: 100%; /* Max Width of the popover (depending on the container!) */
}

#claimProducttable.table > :not(caption) > * > * {
    padding: 0.25rem 0.25rem;
}

#patient_master_form input {
    color: black !important;
}

.td-input {
    display: flex;
    margin: 2px;
}

.td-input input {
    margin: 2px;
    padding: 2px !important;
}

.hcpcs-row-container {
    position: relative;
}

.add-hcpcs-row {
    position: absolute;
    top: 2px;
    right: -10px;
}

.dataTables_scrollBody {
    max-height: 64vh !important;
    /* height: 64vh !important; */
}

.hcpcs_code_form {
    position: relative;
}

.hcpcs_code_form .fa-plus {
    position: absolute;
    top: -5px;
    right: -12px;
}

#calendar .fc-view-harness {
    height: 70vh;
}

/* Dark Theme Css Begins */
.dark-theme .card-header {
    background-color: #222e3c !important;
    margin-bottom: 0;
    border-bottom-width: 1px;
}

.dark-theme .card-title {
    color: #d3d5d8;
    font-size: .925rem;
    font-weight: 600;
}

.dark-theme .card {
    --bs-card-spacer-y: 1.25rem;
    --bs-card-spacer-x: 1.25rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 0;
    --bs-card-border-color: transparent;
    --bs-card-border-radius: 0.25rem;
    --bs-card-inner-border-radius: 0.25rem;
    --bs-card-cap-padding-y: 1rem;
    --bs-card-cap-padding-x: 1.25rem;
    --bs-card-cap-bg: #222e3c;
    --bs-card-bg: #222e3c;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 12px;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: var(--bs-card-bg);
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    display: flex;
    flex-direction: column;
    height: var(--bs-card-height);
    min-width: 0;
    position: relative;
}

.dark-theme body {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    background-color: #19222c;
    color: #bdc0c5;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
}

.dark-theme .table tbody, .dark-theme .table td,.dark-theme .table tfoot,.dark-theme .table th,.dark-theme .table thead,.dark-theme .table tr,.dark-theme .table>:not(:last-child)>:last-child>* {
    border-color: #4e5863;
}

.dark-theme .table {
    color: #bdc0c5;
}

.dark-theme .table>:not(caption)>*>* {
    background-color: transparent;
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px transparent;
    padding: 0.75rem;
}

.dark-theme .table th {
    color:#e3e3e3;
}

.dark-theme .main {
    background: #19222c;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 0;
    overflow: hidden;
    transition: margin-left .35s ease-in-out,left .35s ease-in-out,margin-right .35s ease-in-out,right .35s ease-in-out;
    width: 100%;
}

.dark-theme .h1, .dark-theme .h2, .dark-theme .h3, .dark-theme .h4, .dark-theme .h5, .dark-theme .h6,.dark-theme  h1,.dark-theme h2,.dark-theme h3,.dark-theme h4,.dark-theme h5,.dark-theme h6 {
    color: #fff;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
}

.dark-theme .navbar-bg {
    background: #19222c;
}

.dark-theme .hamburger, .dark-theme .hamburger:after, .dark-theme .hamburger:before {
    background: #bdc0c5;
    border-radius: 1px;
    content: "";
    cursor: pointer;
    display: block;
    height: 3px;
    transition: background .1s ease-in-out,color .1s ease-in-out;
    width: 24px;
}

.dark-theme .nav-flag, .dark-theme .nav-icon {
    color: #a7abb1;
    display: block;
    font-size: 1.5rem;
    line-height: 1.4;
    padding: 0.1rem 0.8rem;
    transition: background .1s ease-in-out,color .1s ease-in-out;
}

.dark-theme .nav-item .dark-theme .text-dark {
    color: #a7abb1 !important;
}

.dark-theme .form-control, .dark-theme .select2-search__field {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    background-clip: padding-box !important;
    background-color: #222e3c !important;
    border: 1px solid #7a828a !important;
    border-radius: 0.2rem !important;
    color: #bdc0c5 !important;
    display: block !important;
    font-size: .875rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    padding: 0.3rem 0.85rem !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.dark-theme .form-control:focus, .dark-theme .select2-search__field:focus {
    background-color: #222e3c !important;
    border-color: #9dbeee !important;
    box-shadow: 0 0 0 0.2rem rgb(59 125 221 / 25%) !important;
    color: #bdc0c5 !important;
    outline: 0 !important;
}

.dark-theme .form-select {
    -moz-padding-start: calc(.85rem - 3px);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #222e3c;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23d3d5d8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
    background-position: right 0.85rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    border: 1px solid #7a828a;
    border-radius: 0.2rem;
    color: #bdc0c5;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.3rem 2.55rem 0.3rem 0.85rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}

.dark-theme .navbar-light .dark-theme .navbar-nav .dark-theme .nav-link {
    color: #a7abb1;
}

.dark-theme .form-check-label {
    color: white;
}

.dark-theme .MuiDataGrid-virtualScrollerContent--overflowed {
    /*background: black;*/
}

.dark-theme label,.dark-theme .fa, .dark-theme .MuiDataGrid-main, .dark-theme .MuiPaginationItem-root{
    color: #ececec;
}

.dark-theme .MuiPaginationItem-root {
    background: #4e5863;
}

.dark-theme .MuiPaginationItem-ellipsis {
    background: none;
}

.dark-theme .Mui-selected {
    color: white !important;
    border: 2px solid #3b7ddd !important;
}

.dark-theme strong {
    color: white;
}

.dark-theme .card-header {
    color: #ececec;
}

/*.dark-theme .form-check-input {*/
/*    color-adjust: exact;*/
/*    -webkit-appearance: none;*/
/*    -moz-appearance: none;*/
/*    appearance: none;*/
/*    background-color: #222e3c;*/
/*    background-position: 50%;*/
/*    background-repeat: no-repeat;*/
/*    background-size: contain;*/
/*    border: 1px solid hsla(0,0%,100%,.5);*/
/*    height: 1em;*/
/*    margin-top: 0.25em;*/
/*    -webkit-print-color-adjust: exact;*/
/*    print-color-adjust: exact;*/
/*    vertical-align: top;*/
/*    width: 1em;*/
/*    color: #bdc0c5 !important;*/
/*}*/

.dark-theme .select2-container--bootstrap-5 .dark-theme .select2-selection, .dark-theme .select2-container--default .dark-theme .select2-selection--single {
    background-color: #222e3c !important;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid hsla(0,0%,100%,.5) !important;
    color: #bdc0c5 !important;

}

.dark-theme .select2-container--bootstrap-5 .dark-theme .select2-selection--single .dark-theme .select2-selection__rendered {
    padding: 0;
    font-weight: 400;
    line-height: 1.5;
    color: #bdc0c5 !important;

}

.dark-theme .select2-container--bootstrap-5 .dark-theme .select2-selection--single {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23d3d5d8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E") !important;
}

.dark-theme .select2-dropdown {
    background-color: #222e3c !important;
    border-color: #534e4e !important;
}

.dark-theme .select2-results {
    color: #d8d7d7 !important;
}

.dark-theme .select2-selection__rendered {
    color: #bdc0c5 !important;
}

/* width */
.dark-theme ::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.dark-theme  ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.dark-theme ::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.dark-theme ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.dark-theme .main .navbar {
    border-bottom: 1px solid #454545;
}
/* Dark Theme Css Ends */

.week-view-calendar {
    min-height: 310px !important;
}

.btn-calendar-details {
    /* position: absolute;
    right: 20px; */
}

.week-view-calendar .fc-header-toolbar {
    display: none !important;
}

.dataTables_filter {
    float: left !important;
}

.dataTables_wrapper  .row:first-child {
    position: relative;
}

.dataTables_wrapper  .row:first-child .col-md-6:first-child {
    /* display: none !important; */

    position: absolute;
    top: 25px;
    /* float: right; */
    left: 10px;
}

.dataTables_length label {
    display: none;
}

#notification_bell .dropdown-menu {
    height: 400px;
    overflow-y: scroll;
}

.report-card {
    text-align: center;
    padding: 20px;
    background: #e9e9e99e;
    border: 1px solid #d2d2d29e;

    /* border: 2px solid #c9c9c9; */
    border-bottom: none;
    cursor: pointer;

}

.report-card .card-title {
    font-size: 16px;
}

.report-card.active {
    border-top: 3px solid #3b7ddd;
    background: white;
}

.post-search-panel {
    margin-top: 10px;
}

.post-search-panel select {
    margin-right: 5px;
    z-index: 1000;
    position: relative;
}

.order-search-panel {
    margin-top: -2px;
}

.small-element {
    width: 100%;
    margin-left: 0px !important;
}

.small-element input, .small-element button {
    height: 26px;
}

.small-element .tracking {
    width: 200px;
    margin-left: 50px;
}

.small-element .qty {
    width: 50px;
}

.po_collapse {
    background: #f0f0f0;
}

.selected-tr {
    background: #f0f0f0;
}

.small-element label {
    background: #d3d3d380;padding: 2px;
    margin-right: 8px;
}

.dt-buttons {
    position: absolute !important;
    right: 0px;
}

.dt-button {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    border-radius: 0.2rem !important;
    color: #495057 !important;
    cursor: pointer !important;
    display: inline-block !important;
    font-size: .875rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    padding: 0.2rem 0.55rem !important;
    text-align: center !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
    vertical-align: middle !important;
    background-color: #3b7ddd !important;
    border-color: #3b7ddd !important;
    color: #ffffff !important;
}

.filter-bucket .navbar {
    padding: .175rem 0.375rem !important
}

.filter-bucket .navbar .nav-link {
    color: white !important;
}

.card-header-small {
    padding: 0.5rem 1.25rem !important;
}

.card-body-small {
    padding: 0.5rem 1.25rem !important;
}

.form-label {
    margin-bottom: 0.2rem !important;
}

.form-control {
    color: #121111 !important;
}

.dataTables_wrapper .table > :not(:first-child) {
    border-top: none !important;
}

.card-title-sm {
    font-size: 0.9rem;
    font-weight: 500;
    color: #5c6a76;
}

#activity-log {
    padding-left: 0 !important;
}

.activity-log span, .activity-log td {
    font-size: 14px !important;
}

.card-body {
    border: 1px solid #f0f2f58a;
}

.claim-card {
    padding: 0px;
}

.btn-small {
    height: 32px;
}

.mb-3 {
    margin-bottom: 0.75rem!important;
}

.filter-card-body {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.sidebar-nav .show {
    padding-left: 25px !important;
}

.form-check-input {
    margin-top: 0px;
}

.card-header {
    padding: 0.75rem 1.25rem ;
}

.css-13cymwt-control {
    min-height: 32px !important;
    height: auto;
}

.css-1jqq78o-placeholder {
    margin-top: -3px;
}

.sun-editor-editable {
    font-size: 15px;
}

.nav-checkbox .form-check-label {
    margin-top: 0px
}

.react-datepicker__year-dropdown {
    height: 150px; /* set the height of the dropdown menu */
    overflow-y: scroll; /* add scroll bar when the menu overflows */
}

.payment-card-body .MuiDataGrid-row {
    min-height: 100px !important;
}

.claim-row {
    height: 75vh;
    overflow-y: scroll;
}

.footer-bottom {
    left: 277px;
    bottom: 0px;
    position: fixed;
    padding-top: -200px;
}

.workAndFlag {
    position: absolute;
    top: 7px;
    left: 280px;
}

.reorderColumn span {
    position: absolute;
    right: -20px;
    top: -20px;
}

.charge-row td:last-child {
    min-width: 68px !important;
}